import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import Seo from "../components/seo";
//Images
import { StaticImage } from "gatsby-plugin-image";

const Builders = () => {
  return (
    <Layout>
      <Seo
        title="Urbanizaciones - BIKESAFE"
        description="Bike Safe ofrece soluciones de aparcabicicletas compactas y de alta calidad para urbanizaciones y grandes desarrollos. Hable con uno de nuestros especialistas para encontrar la mejor opción para su proyecto."
      />
      <Container>
        <Row>
          <Col sm="12">
            <h1 className="mt-5 mb-5 text-center">Urbanizaciones</h1>
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="text-center">
            <p className="flow-text">
              Las soluciones compactas y de alta calidad de Bike Safe facilitan
              el estacionamiento de bicicletas en urbanizaciones y grandes
              desarrollos. Nuestros aparcabicicletas se adaptan perfectamente a
              las necesidades de promotores inmobiliarios, optimizando el
              espacio y aportando valor a los proyectos. Hable con uno de
              nuestros especialistas para encontrar la solución más adecuada
              para su desarrollo.
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="text-center">
            <StaticImage
              placeholder="blurred"
              src="../images/bikestand.jpg"
              className="shadow mt-5 mb-5 fluid rounded"
            />
            <p>
              <em className="flow-text">
                "Estamos plenamente satisfechos con su servicio. ¡Algo muy
                alentador en estos tiempos desafiantes!"
                <strong>
                  {" "}
                  James Whiteley (comprador), Sir Robert McAlpine
                </strong>
                .
              </em>
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Builders;
